.field-allday {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  justify-content: flex-start;
  margin-top: 16px;
}

.field-enteredBy {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}
