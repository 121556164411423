.customeBox {
  margin-top: 144px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  border-bottom: 2px solid #edb24b;
  color: #edb24b;
  font-family: Monotype Corsiva !important;
  font-size: 30px !important;
  font-weight: 700 !important;
  letter-spacing: 10px !important;
  line-height: 5px ;
  margin-bottom: 60px !important;
  padding: 5px 25px;
  text-align: center;
  text-shadow: 4px 5px 6px #fff;
}
