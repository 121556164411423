.rbc-month-view {
  min-height: 600px;
}

.rbc-header {
  font-weight: 700;
}

.custom-date-cell {
  position: relative;
}

.rbc-event {
  pointer-events: auto;
}

.custom-date-cell .rbc-event {
  margin: 2px 0;
}
.rbc-time-header{
    margin-right: 11px;
}

.rbc-time-header.rbc-overflowing {
    border-right: 1px solid #ddd;
}




