.dateRangeWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
}
.dateRangeWrapper > :not(style) + :not(style) {
  margin: 0px 0px 0px 24px;
}
.dateRangeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.noteWrapper {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}
