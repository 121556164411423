body {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  background-color: rgb(255, 255, 255);
  background-image: radial-gradient(
    closest-side at 27% 185%,
    rgb(249, 246, 241) 0%,
    rgb(215, 208, 197) 100%
  );
  background-repeat: no-repeat;
  background-attachment: fixed;
}

* {
  scrollbar-color: #464646 auto;
  scrollbar-width: thin;
}

*,
::before,
::after {
  box-sizing: inherit;
}

.root-wrapper {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-style {
  background-color: transparent !important;
  padding-bottom: 20px;
  padding-top: 20px;
}

.btn-logout {
  background-color: #fff !important;
  border: 1px solid orange !important;
  border-radius: 5px !important;
  color: orange !important;
  letter-spacing: 5px !important;
  padding: 10px 15px !important;
  transition: 0.5s !important;
}

.login-username {
  font-family: Monotype Corsiva !important;
  text-shadow: 4px 5px 6px #000 !important;
}

.header-title {
  background: transparent !important;
  color: #edb24b !important;
  font-family: Monotype Corsiva !important;
  font-size: 180% !important;
  font-weight: 700 !important;
  letter-spacing: 2px !important;
  margin: 0 !important;
  padding: 0 !important;
  text-shadow: 4px 5px 6px #fff !important;
  vertical-align: baseline !important;
}

.nav-bar {
  background-color: #edb24b !important;
  border-bottom: 2px solid #e99d1f !important;
  border-top: 2px solid #e99d1f !important;
  padding: 0 !important;
  width: 100% !important;
}

.hide-fieldset fieldset {
  display: none;
}

.monthly-reserve-count .count-reserve-day {
  background: #2ecc87;
  border-radius: 50%;
  color: #fff;
  height: 25px;
  margin: 4px auto;
  text-align: center;
  width: 25px;
}

.monthly-reserve-count .text-day {
  font-size: 18px;
  padding: 12px 0;
  text-align: center;
  width: 46px;
}

.monthly-reserve-count .text-day:hover {
  background: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.monthly-reserve-count .cell-day.active-day {
  background: rgba(116, 185, 255, 0.5);
}

.text-day.active-day {
  background-color: rgb(140, 255, 146);
  color: white;
}

.reservationTable th {
  border: 2px solid #e0e0e0;
}

.storeTherapistName {
  width: 400px !important;
}

.reserve-cell-status-0,
.reserve-cell-status-0:hover {
  background-color: grey;
  opacity: 0.5;
}
.reserve-cell-status-1 {
  background-color: #fff;
  border: 1px dotted grey;
  transition: 0.3s;
}
.reserve-cell-status-1:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.reserve-cell-status-0 {
  border: none;
}
.timePiece {
  height: 50px;
  width: 16.6666%;
}

.calendar_container {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}
.calendar_paper_container {
  padding-left: 64px;
  padding-right: 64px;
  height: 75vh;
  overflow: auto;
}
