.rc-picker-footer {
  background: cornflowerblue;
}

.rc-picker-now {
  display: flex;
  justify-content: center;
  font-size: 14px;
}

.select-time {
  display: flex;
  gap: 4px;
  font-size: 12px;

  select {
    padding: 5px 0;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    border-radius: 4px;
    font-size: 10px;
  }
}

.select-store {
  padding: 3px 0;
  height: 30px;
  width: 78px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  font-size: 10px;
}